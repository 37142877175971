h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

.reactour__popover div button svg {
  color: white !important
}