#date-picker,
#source-date-picker {
  margin-top: 26px;
  padding-bottom: 1.5px;
  border-bottom: 1px solid rgb(118, 118, 118);
}

#dark-picker #date-picker,
#dark-picker #source-date-picker {
  margin-top: 26px;
  padding-bottom: 5;
  border-bottom: 1px solid rgb(229, 231, 235);
  color: #ffffff;
}

#date-picker::placeholder,
#source-date-picker::placeholder {
  color: rgb(113 113 122);
}

#dark-picker #date-picker::placeholder,
#dark-picker #source-date-picker::placeholder {
  color: #9e9e9e;
}

#date-picker:hover,
#source-date-picker:hover {
  border-bottom: 1px solid black;
}
#date-picker:active,
#source-date-picker:active {
  border-bottom: 1px solid blue;
}

#dark-picker #date-picker,
#dark-picker #source-date-picker {
  background: #656161;
  color: #ffffff;
}

#dark-picker .react-datepicker__header {
  background-color: #5d5959;
  color: #ffffff;

  .react-datepicker__current-month {
    color: #ffffff;
  }
  .react-datepicker__year-dropdown {
    background-color: #5d5959;
  }
}

#dark-picker .react-datepicker__day-name {
  color: #ffffff;
}

#dark-picker .react-datepicker {
  background-color: #767272;
  color: #ffffff;
}

#dark-picker #date-picker {
  color: #ffffff;
}
